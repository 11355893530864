var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dx-util-popup',{ref:"monthlySalesPopupRef",attrs:{"drag-enabled":false,"close-on-outside-click":true,"show-close-button":true,"show-title":true,"title":_vm.popupTitle,"height":"auto","width":"auto","full-screen":false,"position":'center'},on:{"shown":_vm.monthlySalesPopupShown,"hidden":_vm.monthlySalesPopupHidden}},[_c('dx-util-scroll-view',{attrs:{"width":"100%","height":"100%"}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center"},[_c('DxChart',{attrs:{"id":"monthlySalesChart","data-source":_vm.chartData}},[_c('DxCommonSeriesSettings',{attrs:{"argument-field":"period","type":"stackedbar"}}),_c('DxValueAxis',{attrs:{"position":"left","format":function (value) { return (value / 1000).toFixed(0) + 'K'; }}},[_c('DxTitle',{attrs:{"text":"Net Sales $"}}),_c('DxLabel',{attrs:{"format":function (value) { return (value / 1000).toFixed(0) + 'K'; }}})],1),_c('DxTitle',{attrs:{"text":"Monthly Sales Snapshots","subtitle":""}}),_c('DxSeries',{attrs:{"value-field":"cogs","name":"COGS","label":{
            visible: true,
            position: 'inside',
            format: 'thousands',
            customizeText: _vm.customizeLabel
          }}}),_c('DxSeries',{attrs:{"value-field":"amazonFees","name":"Amazon Fees","label":{
            visible: true,
            position: 'inside',
            format: 'thousands',
            customizeText: _vm.customizeLabel
          }}}),_c('DxSeries',{attrs:{"value-field":"warehouseFees","name":"Warehouse Fees","label":{
            visible: true,
            position: 'inside',
            format: 'thousands',
            customizeText: _vm.customizeLabel
          }}}),_c('DxSeries',{attrs:{"value-field":"profit","name":"Profit","label":{
            visible: true,
            position: 'inside',
            format: 'thousands',
            customizeText: _vm.customizeLabel
          }}}),_c('DxLegend',{attrs:{"vertical-alignment":"bottom","horizontal-alignment":"center","item-text-position":"right"}}),_c('DxExport',{attrs:{"enabled":true}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }