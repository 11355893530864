<template>
  <dx-util-popup
    ref="monthlySalesPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="true"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    height="auto"
    width="auto"
    :full-screen="false"
    :position="'center'"
    @shown="monthlySalesPopupShown"
    @hidden="monthlySalesPopupHidden"
  >
  <dx-util-scroll-view width="100%" height="100%">
    <div class="d-flex justify-content-center align-items-center">
        <DxChart
          id="monthlySalesChart"
          :data-source="chartData"
        >
          <DxCommonSeriesSettings
            argument-field="period"
            type="stackedbar"
          />
          <DxValueAxis position="left" :format="value => (value / 1000).toFixed(0) + 'K'">
            <DxTitle text="Net Sales $" />
            <DxLabel
              :format="value => (value / 1000).toFixed(0) + 'K'"
            />
          </DxValueAxis>
          <DxTitle
            text="Monthly Sales Snapshots"
            subtitle=""
          />
          <DxSeries
            value-field="cogs"
            name="COGS"
            :label="{
              visible: true,
              position: 'inside',
              format: 'thousands',
              customizeText: customizeLabel
            }"
          />
          <DxSeries
            value-field="amazonFees"
            name="Amazon Fees"
            :label="{
              visible: true,
              position: 'inside',
              format: 'thousands',
              customizeText: customizeLabel
            }"
          />
          <DxSeries
            value-field="warehouseFees"
            name="Warehouse Fees"
            :label="{
              visible: true,
              position: 'inside',
              format: 'thousands',
              customizeText: customizeLabel
            }"
          />
          <DxSeries
            value-field="profit"
            name="Profit"
            :label="{
              visible: true,
              position: 'inside',
              format: 'thousands',
              customizeText: customizeLabel
            }"
          />
          <DxLegend
            vertical-alignment="bottom"
            horizontal-alignment="center"
            item-text-position="right"
          />
          <DxExport :enabled="true" />
        </DxChart>
      </div>
    </dx-util-scroll-view>
  </dx-util-popup>
</template>

<script>
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxValueAxis,
  DxTitle,
  DxLegend,
  DxLabel,
} from 'devextreme-vue/chart'

export default {
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxValueAxis,
    DxTitle,
    DxLegend,
    DxLabel,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    chartRawData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      popupTitle: '',
    }
  },
  computed: {
    monthlySalesPopup() {
      return this.$refs.monthlySalesPopupRef.instance
    },
    chartData() {
      return this.chartRawData.map(item => {
        const month = item.month.toString().padStart(2, '0')
        const period = `${item.year}-${month}`

        return {
          period,
          cogs: -item.cogs,
          amazonFees: -item.amzFulfillment,
          warehouseFees: item.warehouseServiceFees + item.warehouseShippingFees,
          profit: item.profit,
          totalSales: item.sales,
        }
      }).sort((a, b) => a.period.localeCompare(b.period))
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.monthlySalesPopup.show()
      },
    },
  },
  methods: {
    monthlySalesPopupShown() {
      this.popupTitle = 'Monthly Sales Snapshots'
    },
    monthlySalesPopupHidden() {

    },
    closePopup() {
      this.monthlySalesPopup.hide()
    },
    customizeLabel(pointInfo) {
      if (pointInfo.value > 0) {
        return pointInfo.value.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
#monthlySalesChart {
  width: 90vw;
  min-height: 600px;
}
</style>
